.harmonogram .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.harmonogram button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.harmonogram button:hover {
    background-color: red;
}

.harmonogram .messageBox p {
    color: red;
}
.harmonogram .messageBox.negative p {
    color: red;
}
.harmonogram .messageBox.pozitive p {
    color: green;
}
.harmonogram .messageBox button {
    background-color: lightgray;
}
.harmonogram .messageBox button:hover {
    background-color: red;
}

@media only screen and (min-width: 768px) {
    .harmonogram > .layout {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    .harmonogramHarmonogram {
        grid-column: 1;
        padding-right: 40px;
    }
    .harmonogramSummary {
        grid-column: 2;
    }
}

.harmonogramStateList {
    margin: 30px 0 20px 0;
    list-style: none;
    display: table;
    border-collapse: collapse;
}
.harmonogramStateList > li {
    display: contents;
}
.harmonogramStateList > li.current {
    font-size: 14pt;
}
.harmonogramStateList > li:last-child > .link {
    border-bottom: none;
}
.harmonogramStateList > li > .link {
    display: table-row;
    border-bottom: solid 1px #c0c0c0;
}
.harmonogramStateList > li.client.current > .link {
    background-color: #d1f1dc;
}
.harmonogramStateList > li.company.current > .link {
    background-color: #e4f1fe;
}
.harmonogramStateList > li.client.finished > .link .date {
    background-image: url('images/state_client_finished.svg');
}
.harmonogramStateList > li.client.current > .link .date {
    background-image: url('images/state_client_current.svg');
}
.harmonogramStateList > li.client.future > .link .date {
    background-image: url('images/state_client_future.svg');
}
.harmonogramStateList > li.company.finished > .link .date {
    background-image: url('images/state_company_finished.svg');
}
.harmonogramStateList > li.company.current > .link .date {
    background-image: url('images/state_company_current.svg');
}
.harmonogramStateList > li.company.future > .link .date {
    background-image: url('images/state_company_future.svg');
}
.harmonogramStateList > li.finished > .link {
    color: #b0b0b0;
}
.harmonogramStateList > li.future .link {
    color: #333333;
}
.harmonogramStateList > li:is(.finished,.current,.future) > a:hover {
    background-color: #fffbeb;
}
.harmonogramStateList > li .date {
    display: table-cell;
    padding: 5px 10px 5px 47px;
    text-align: center;
    background-position: 5px 5px;
    background-repeat: no-repeat;
}
.harmonogramStateList > li .content {
    display: table-cell;
    padding: 5px 29px 5px 5px;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url('images/arrow.svg');
}
.harmonogramStateList > li .name {
    display: block;
    padding: 5px;
}
.harmonogramStateList > li .properties {
    display: flex;
    flex-direction: row;
    padding: 5px;
    font-size: 10pt;
}
.harmonogramStateList > li .properties > * {
    margin-right: 5px;
}
.harmonogramStateList > li .properties .delay {
    padding: 2px 8px;
    color: #ffffff;
    border-radius: 2px;
}
.harmonogramStateList > li .properties .delay.company {
    background-color: #0b6fc7;
}
.harmonogramStateList > li .properties .delay.client {
    background-color: #0bc74c;
}
.harmonogramStateList > li .properties .delay.god {
    background-color: #e0c215;
}
.harmonogramStateList > li .properties .icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
}
.harmonogramStateList > li .properties .icon.clima {
    background-image: url('images/icon_clima.svg');
}
.harmonogramStateList > li .properties .icon.hurdle {
    background-image: url('images/icon_hurdle.svg');
}
.harmonogramStateList > li.summary {
    display: table-row;
    color: #803300;
    font-size: 14pt;
}
.harmonogramStateList > li.summary .date strong {
    background-color: #ffe6d5;
    padding: 0 5px;
}


.harmonogramSummary h2 {
    margin-bottom: 15px;
}
.harmonogramSummary > div h3 {
    margin-bottom: 10px;
}
.harmonogramSummary > div ul {
    padding: 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.harmonogramSummary > div ul li {
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.harmonogramSummary > div ul li.summary {
    font-weight: bold;
}
.harmonogramSummary > div.client {
    color: #077b2f;
}
.harmonogramSummary > div.client ul {
    background-color: #d1f1dc;
}
.harmonogramSummary > div.company {
    color: #09589e;
}
.harmonogramSummary > div.company ul {
    background-color: #d1e5f6;
}
.harmonogramSummary > div.god {
    color: #87750d;
}
.harmonogramSummary > div.god ul {
    background-color: #fff6d5;
}


.harmonogramNoticeList .notice {
    border-radius: 5px;
    background-color: #fff6d5;
    color: #554400;
    padding: 10px;
    margin: 15px 0;
}
.harmonogramNoticeList .notice h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: left center;
}
.harmonogramNoticeList .notice.hurdle h3 {
    background-image: url('images/notice_hurdle.svg');
}
.harmonogramNoticeList .notice.cooperation h3 {
    background-image: url('images/notice_cooperation.svg');
}
.harmonogramNoticeList .notice.clima h3 {
    background-image: url('images/notice_clima.svg');
}
.harmonogramNoticeList .notice p {
    font-size: 10pt;
}


.state .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.state button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.state button:hover {
    background-color: red;
}

.state .messageBox p {
    color: red;
}
.state .messageBox.negative p {
    color: red;
}
.state .messageBox.pozitive p {
    color: green;
}
.state .messageBox button {
    background-color: lightgray;
}
.state .messageBox button:hover {
    background-color: red;
}

.state .name {
    padding-left: 36px;
    background-position: left center;
    background-repeat: no-repeat;
    font-size: 14pt;
    line-height: 32px;
    margin-top: 30px;
    margin-bottom: 20px;
}
.state .name.company.finished {
    background-image: url('images/state_company_finished.svg');
}
.state .name.company.current {
    background-image: url('images/state_company_current.svg');
}
.state .name.company.future {
    background-image: url('images/state_company_future.svg');
}
.state .name.client.finished {
    background-image: url('images/state_client_finished.svg');
}
.state .name.client.current {
    background-image: url('images/state_client_current.svg');
}
.state .name.client.future {
    background-image: url('images/state_client_future.svg');
}

.state .notice {
    border-radius: 5px;
    background-color: #fff6d5;
    color: #554400;
    padding: 10px;
    margin: 15px 0;
}
.state .notice h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: left center;
}
.state .notice.hurdle h3 {
    background-image: url('images/notice_hurdle.svg');
}
.state .notice.cooperation h3 {
    background-image: url('images/notice_cooperation.svg');
}
.state .notice.clima h3 {
    background-image: url('images/notice_clima.svg');
}
.state .notice p {
    font-size: 10pt;
}

.state .time {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}
.state .time > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.state .time > .end {
    border-left: solid 1px #000000;
}
.state .time .date {
    font-size: 16pt;
    color: #085294;
    margin-bottom: 10px;
}
.state .time > .end.delay {
    color: #803300;
}

.state .log {
    margin-top: 40px;
}
.state .log > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
}
.state .log > p {
    font-size: 11pt;
}

.state .hurdleList {
    margin-top: 40px;
}
.state .hurdleList > h3 {
    font-size: 12pt;
    font-weight: bold;
}
.state .hurdleList > ul {
    margin-top: 15px;
}
.state .hurdleList > ul > li {
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 10px;
}
.state .hurdleList > ul > li > span {
    font-weight: bold;
}
.state .hurdleList > ul > li > p {
    margin-top: 10px;
}
.state .hurdleList .end::before {
    content: ' - ';
}

.state .delay {
    margin-top: 40px;
}
.state .delay > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
}
.state .delay > div {
    margin-top: 20px;
}
.state .delay > div > .length {
    padding: 2px 8px;
    color: #ffffff;
    border-radius: 2px;
    margin-right: 5px;
}
.state .delay > div > .title {
    padding: 2px;
}
.state .delay > div.company > .length {
    background-color: #0b6fc7;
}
.state .delay > div.company > .title {
    color: #0b6fc7;
}
.state .delay > div.client > .length {
    background-color: #0bc74c;
}
.state .delay > div.client > .title {
    color: #0bc74c;
}
.state .delay > div.god > .length {
    background-color: #e0c215;
}
.state .delay > div.god > .title {
    color: #e0c215;
}

.state .description {
    margin-top: 40px;
}
.state .description > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
}
.state .description > p {
    font-size: 11pt;
    white-space: pre-wrap;
}
