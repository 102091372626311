.index ul li.harmonogram a {
    background-image: url('../common/images/ico_schedule_135.svg');
}
.index ul li.message a {
    background-image: url('../common/images/ico_message_135.svg');
}
.index ul li.helpline a {
    background-image: url('../common/images/ico_director_135.svg');
}
.index ul li.file a {
    background-image: url('../common/images/ico_download_135.svg');
}
.index ul li.loan a {
    background-image: url('../common/images/ico_loans_135.svg');
}
.index ul li.profile a {
    background-image: url('../common/images/ico_profile_135.svg');
}
.index ul li.reference a {
    background-image: url('../common/images/ico_fee_gold_145.svg');
}
.index ul li.service a {
    background-image: url('../common/images/ico_servis_135.svg');
}

@media only screen and (min-width: 768px) {
    .index {
        margin-top: 40px;
    }
    .index h2 {
        display: none;
    }
    .index ul {
        margin-top: 80px;
        margin-bottom: 100px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        gap: 100px 0;
    }
    .index ul li {
        display: contents;
    }
    .index ul li a {
        padding-top: 150px;
        background-size: 150px 150px;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .index ul li.reference {
        display: none;
    }
    .index .reference a {
        padding-left: 380px;
        background-size: 350px 250px;
        background-position: 0px top;
        background-repeat: no-repeat;
        background-image: url('../common/images/ico_fee_gold.svg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
        margin: 0 auto;
        height: 250px;
        margin-top: 100px;
    }
    .index .reference a h3::first-letter {
        text-transform: uppercase;
    }
    .index .reference a .description {
        display: block;
        color: initial;
    }
}
@media only screen and (max-width: 767px) {
    .index {
        margin-top: 40px;
    }
    .index h2 {
        /* display: none; */
        display: block;
        padding: 0 20px;
    }
    .index ul {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 30px 0;
    }
    .index ul li {
        display: contents;
    }
    .index ul li a {
        padding-top: 120px;
        background-size: 120px 120px;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .index > .reference {
        display: none;
    }
}
