.loan .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.loan button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.loan button:hover {
    background-color: red;
}

.loan .messageBox p {
    color: red;
}
.loan .messageBox.negative p {
    color: red;
}
.loan .messageBox.pozitive p {
    color: green;
}
.loan .messageBox button {
    background-color: lightgray;
}
.loan .messageBox button:hover {
    background-color: red;
}

.loanLoanList ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-template-rows: min-content;
    gap: 20px;
}

.loanLoan {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 15px 20px;
    box-sizing: border-box;
    padding: 20px;
    /* margin: 10px; */
    border-radius: 5px;
    background-color: lightgrey;
}
.loanLoan.paid {
    background-color: rgb(233, 233, 233);
}
.loanLoan.expired {
    background-color: rgb(250, 207, 207);
}
.loanLoan.running {
    background-color: rgb(221, 250, 207);
}
.loanLoan > span {
    font-size: 11pt;
    display: contents;
}
.loanLoan > .number {
    font-weight: bold;
}
.loanLoan span.value {
    text-align: right;
}
.loanLoan.expired .currentAmount .value {
    font-weight: bold;
}


@media only screen and (max-width: 767px) {
    .loan {
        padding-left: 10px;
        padding-right: 10px;
    }
    .loan .motivation h3 {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .loan {
        display: grid;
        grid-template-columns: 408px 1fr;
        gap: 20px;
    }
    .loan .motivation {
        grid-column: 1;
        grid-row: 1 / span 2;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(../common/images/ico_loans_135.svg);
    }
    .loan .layout {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
}