.reference .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
}
.reference > .activityIndicatorContainer {
    height: 80vh;
}
.reference button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.reference .messageBox p {
    color: red;
}
.reference .messageBox.negative p {
    color: red;
}
.reference .messageBox.pozitive p {
    color: green;
}
.reference .messageBox button {
    background-color: lightgray;

}
.reference .messageBox button:hover {
    background-color: red;
}

.referenceNewReference button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.referenceNewReference button:hover {
    background-color: red;
}
.referenceNewReference .referenceForm {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    max-width: 400px;
}
.referenceNewReference .referenceForm form {
    display: contents;
}
.referenceNewReference .referenceForm form label {
    display: flex;
    flex-direction: column;
}
.referenceNewReference .referenceForm input:not([type="submit"]) {
    outline: none;
    appearance: none;
    padding: 3px;
    border-radius: 3px;
    border: solid 1px #cccccc;
    font-size: 12pt;
    line-height: 1.7em;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 3px;
}
.referenceNewReference .referenceForm input:not([type="submit"]):valid {
    padding-right: 27px;
    background-image: url('../common/images/valid.svg');
    background-position: right 3px center;
    background-repeat: no-repeat;
}
.referenceNewReference input[type="submit"] {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.referenceNewReference input[type="submit"]:hover {
    background-color: red;
}
.referenceNewReference .activityIndicatorContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,1);
}
.referenceNewReference .cancel {
    background-color: lightgray;
}

.referenceReferenceList ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-template-rows: min-content;
    gap: 20px;
}
.referenceReferenceList .activityIndicatorContainer {
    height: 80vh;
}

.referenceReference {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 15px 20px;
    box-sizing: border-box;
    padding: 20px;
    /* margin: 10px; */
    border-radius: 5px;
    background-color: rgb(245, 245, 245);
}
.referenceReference.paid {
    background-color: lightgrey;
}
.referenceReference.expired {
    background-color: rgb(250, 207, 207);
}
.referenceReference.running {
    background-color: rgb(221, 250, 207);
}
.referenceReference > span {
    font-size: 11pt;
    display: contents;
}
.referenceReference > .number {
    font-weight: bold;
}
.referenceReference span.value {
    text-align: right;
}
.referenceReference.expired .currentAmount .value {
    font-weight: bold;
}


@media only screen and (max-width: 767px) {
    .reference {
        padding-left: 10px;
        padding-right: 10px;
    }
    .reference .motivation h3 {
        display: none;
    }
    .referenceNewReference {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .reference {
        display: grid;
        grid-template-columns: 408px 1fr;
        gap: 20px;
    }
    .reference .motivation {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(../common/images/ico_fee_gold_145.svg);
    }
    .reference .layout {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
    .referenceNewReference {
        margin-top: 20px;
        margin-bottom: 50px;
    }
}