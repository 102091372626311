.file {
    position: relative;
    top: 0;
    left: 0;
}
.file .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: rgba(240,240,240,1);
}


.fileFileList {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
}

.fileFile {
    position: relative;
    top: 0;
    left: 0;
}
.fileFile:last-child .fileData {
    border-bottom: none;
}
.fileFile .fileData:hover {
    background-color: rgba(240,240,240,1);
}
.fileFile .fileData {
    cursor: pointer;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 48px 48px;
    display: grid;
    grid-template-columns: 48px 1fr max-content;
    grid-template-rows: 24px;
    gap: 5px;
    padding: 20px 0;
    border-bottom: solid 1px lightgray;
    max-width: 100%;
}
.fileFile .fileData.pdf {
    background-image: url(images/pdf-svgrepo-com.svg);
}
.fileFile .fileData.jpg {
    background-image: url(images/jpg-svgrepo-com.svg);
}
.fileFile .fileData.png {
    background-image: url(images/png-svgrepo-com.svg);
}
.fileFile .fileData.doc {
    background-image: url(images/doc-svgrepo-com.svg);
}
.fileFile .fileData.xls {
    background-image: url(images/xls-svgrepo-com.svg);
}
.fileFile .fileData.txt {
    background-image: url(images/txt-svgrepo-com.svg);
}
.fileFile .fileData.zip {
    background-image: url(images/zip-svgrepo-com.svg);
}
.fileFile .fileData span.name {
    grid-column: 2;
    grid-row: 1 / span 2;
    align-self: center;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fileFile .fileData span.type {
    display: none;
}
.fileFile .fileData span.size {
    grid-column: 3;
    grid-row: 1;
    color: initial;
}
.fileFile .fileData span.date {
    grid-column: 3;
    grid-row: 2;
    color: initial;
}
.fileFile .curtainContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,0.5);
}
.fileFile .progressBarContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .file {
        padding-left: 10px;
        padding-right: 10px;
    }
    .file .motivation h3 {
        display: none;
    }
    .fileFileList {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .file {
        display: grid;
        grid-template-columns: 408px 1fr;
        gap: 20px;
    }
    .file .motivation {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(../common/images/ico_download_135.svg);
    }
    .file .layout {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
}