.messageBox p {
    color: red;
}
.messageBox.negative p {
    color: red;
}
.messageBox.pozitive p {
    color: green;
}


.loginPage {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
}

.login {
    width: 300px;
    padding: 0;
    margin: 0;
    flex-direction: column;
}
.login .messageBoxContainer {
    width: 100%;
    margin-bottom: 10px;
}
.login .loginForm {
    width: 300px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 3px;
    box-sizing: border-box;
}
.login .loginForm input {
    width: 100%;
    outline: none;
    appearance: none;
    padding: 8px 3px;
    border-radius: 3px;
    border: none;
    font-size: 12pt;
    line-height: 1.7em;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: #ffffff;
    margin-top: 3px;
}
.login .loginForm input:valid {
    padding-right: 27px;
    background-image: url('../common/images/valid.svg');
    background-position: right 3px center;
    background-repeat: no-repeat;
}
.login .loginForm button {
    align-self: center;
    margin-top: 10px;
}
.login .loginForm .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(14pt * 1.4 + 10px);
}
.login .loginForm .button {
    appearance: none;
    border: none;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    background-color: #0b6fc7;
    color: #ffffff;
    outline: none;
}
.login .loginForm .button:hover {
    background-color: red;
}

.passwordRequestPage {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
}

.passwordRequestGenerated {
    width: 100%;
    max-width: 300px;
    padding: 0;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.passwordRequestGenerated button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.passwordRequestGenerated button:hover {
    background-color: red;
}
.passwordRequestGenerated .welcome,
.passwordRequestGenerated .result {
    background-color: #f0f8ff;
    border-radius: 5px;
    padding: 10px;
}
.passwordRequestGenerated .welcome {
    display: flex;
    flex-direction: column;
}
.passwordRequestGenerated .welcome .generate {
    display: flex;
    flex-direction: column;
}
.passwordRequestGenerated .welcome .generate button {
    align-self: center;
}
.passwordRequestGenerated .welcome .generate .activityIndicatorContainer {
    align-self: center;
}
.passwordRequestGenerated .description {
    margin-bottom: 10px;
}
.passwordRequestGenerated .description p {
    padding: 0;
    margin: 0;
}
.passwordRequestGenerated .result {
    display: flex;
    flex-direction: column;
}
.passwordRequestGenerated .result .description {
    margin-top: 10px;
}
.passwordRequestGenerated .result .password {
    display: flex;
    flex-direction: column;
}
.passwordRequestGenerated .result .password label {
    display: grid;
    grid-template-columns: 1fr, max-content;
    gap: 5px;
    margin-top: 10px;
}
.passwordRequestGenerated .result .password label input {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    box-sizing: border-box;

    outline: none;
    appearance: none;
    padding: 3px;
    border-radius: 3px;
    border: none;
    font-size: 12pt;
    line-height: 1.7em;
    box-sizing: border-box;
}
.passwordRequestGenerated .result .password label button {
    grid-column: 2;
    grid-row: 1;
    background-color: lightgray;
}
.passwordRequestGenerated .result .password label button:hover {
    background-color: red;
}
.passwordRequestGenerated .result .control {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.passwordRequestGenerated .result .control button {
    align-self: center;
}
