.helpline button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.helpline button:hover {
    background-color: red;
}

.helpline .messageBox p {
    color: red;
}
.helpline .messageBox.negative p {
    color: red;
}
.helpline .messageBox.pozitive p {
    color: green;
}
.helpline .messageBox button {
    background-color: lightgray;
}
.helpline .messageBox button:hover {
    background-color: red;
}

.helpline .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.helpline .layout {
    position: relative;
    top: 0;
    left: 0;
}
.helpline .layout .activityIndicatorContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,1);
}
.helpline .layout .title {
    font-size: 12pt;
}
.helpline .layout textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    min-height: 200px;
    font-size: 11pt;
    font-family: inherit;
}
.helpline .newMessage img {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .helpline {
        padding-left: 10px;
        padding-right: 10px;
    }
    .helpline .motivation h3 {
        display: none;
    }
    .helpline .newMessage {
        margin-top: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .helpline {
        display: grid;
        grid-template-columns: 408px 1fr;
        gap: 20px;
    }
    .helpline .motivation {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(../common/images/ico_director_135.svg);
    }
    .helpline .newMessage {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
}