.activityIndicator {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0,0,0,0.5);
}

/**
 * localActivityIndicator
 */
.localActivityIndicator {
    display: inline-block;
}
.localActivityIndicator:after {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    content: " ";
    display: block;
    border-radius: 50%;
    border-style: solid;
    border-color: rgb(50, 113, 230) transparent rgb(50, 113, 230) transparent;
    animation: localActivityIndicator 1.2s linear infinite;
}
@keyframes localActivityIndicator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.localActivityIndicatorSmall {
    width: 32px;
    height: 32px;
    padding: 3px;
}
.localActivityIndicatorSmall:after {
    border-width: 3px;
}
.localActivityIndicatorMedium {
    width: 48px;
    height: 48px;
    padding: 4px;
}
.localActivityIndicatorMedium:after {
    border-width: 5px;
}
.localActivityIndicatorBig {
    width: 64px;
    height: 64px;
    padding: 6px;
}
.localActivityIndicatorBig:after {
    border-width: 6px;
}

.localProgressBar {
    width: 100px;
    background-color: rgb(194, 194, 194);
}
.localProgressBar > div {
    height: 100%;
    background-color: rgb(50, 113, 230);
}
.localProgressBar.small {
    height: 10px;
}
.localProgressBar.medium {
    height: 20px;
}
.localProgressBar.big {
    height: 30px;
}


/**
 * Page
 */
 @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
 @import url('https://fonts.googleapis.com/css?family=Oxygen&subset=latin,latin-ext');
 
 
a, a:visited {
     text-decoration: none;
     color: #0b6fc7;
     cursor: pointer;
}
a, a:visited, a:hover {
    text-decoration: none;
}
h1, h2, h3, h4 {
    font-weight: normal;
}
button {
    padding: 0;
    margin: 0;
    cursor: pointer;
}
ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

body {
    padding: 0;
    margin: 0;
    font-family: 'Oxygen',Calibri,Helvetica,Arial,sans-serif;
}
.page {
    width: 100%;
    padding: 0;
    margin: 0;
    display: grid;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}
.logo {
    height: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    overflow: hidden;
    display: block;
}
.breadcrumbs {
    /* background-color: beige; */
}
.content {
    /* background-color: crimson; */
}
.user {
    /* background-color: yellow; */
}
.menu {
    /* background-color: #ebecec; */
}

@media only screen and (min-width: 768px) {
    .header {
        height: 126px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .logo {
        width: 176px;
        background-image: url('images/logo_poz_176x126.svg');
        padding-top: 126px;
    }
    .title {
        width: 232px;
        height: 126px;
        background-color: #ebecec;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
    }
    .title h1 {
        font-size: 13pt;
        margin: 0;
        padding: 0;
    }
    .user {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        padding: 10px 10px;
    }
    .user ul {
        display: grid;
        grid-template-columns: max-content max-content;
        gap: 10px;
    }
    .user ul li {
        display: contents;
    }
    .user ul li span.item::after {
        content: ':';
    }
    .control {
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
    }
    .control .logoutButton {
        border: none;
        background-color: transparent;
        color: #0b6fc7;
        font-size: 12pt;
        cursor: pointer;
    }
    .breadcrumbs ul {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        gap: 10px;
    }
    .breadcrumbs ul li {
        line-height: 48px;
        padding-right: 20px;
        background-image: url('images/navi_separator.svg');
        background-position: right center;
        background-repeat: no-repeat;
        text-transform: uppercase;
    }
    .breadcrumbs ul li:last-child {
        background-image: none;
    }
    .content {
        margin: 0 0 20px 0;
    }
    .menuButton {
        display: none;
    }
    .menu {
        background-color: #ebecec;
    }
    .menu .closeButton {
        display: none;
    }
    .menu h3 {
        display: none;
    }
    .menu ul {
        columns: 3;
        padding: 10px 20px;
    }
    .menu ul li {
        line-height: 40px;
    }
    .menu ul li.current .link {
        color: #074275;
    }
    .menu ul li .link {
        display: inline-block;
    }
    .menu ul li .link::first-letter {
        text-transform: uppercase;
    }
    .topButton {
        display: none;
    }
    .userButton {
        display: none;
    }
    .address {
        margin: 15px 15px 20px 15px;
    }
}
@media only screen and (max-width: 767px) {
    .header {
        height: 48px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
        background-color: whitesmoke;
    }
    .logo {
        position: absolute;
        left: 0;
        top: 0;
        width: 95px;
        background-image: url('images/logo_poz_95x48.svg');
        padding-top: 48px;
    }
    .title {
        display: none;
    }
    .user {
        display: none;
    }
    .control {
        display: none;
    }
    .menuButton {
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: border-box;
        appearance: none;
        width: 48px;
        height: 48px;
        border: none;
        background-image: url('images/menu_btn_48x48.svg');
        overflow: hidden;
        padding-top: 48px;
        background-color: transparent;
        border-radius: 50%;
    }
    .userButton {
        display: none;
        /* position: absolute;
        top: 0;
        right: 48px;
        box-sizing: border-box;
        appearance: none;
        width: 48px;
        height: 48px;
        border: none;
        background-image: url('images/user_btn_48x48.svg');
        overflow: hidden;
        padding-top: 48px;
        background-color: transparent;
        border-radius: 50%; */
    }
    .topButton {
        position: fixed;
        right: 10px;
        bottom: 10px;
        z-index: 10;
        width: 48px;
        height: 0;
        padding-top: 48px;
        overflow: hidden;
        border: none;
        border-radius: 50%;
        background-color: rgba(100,100,100,0.5);
        background-image: url('images/top_btn_48x48.svg');
    }
    .topButton.invisible {
        display: none;
    }
    .menu {
        background-color: rgba(255,255,255,1);
        z-index: 11;
        overflow: scroll;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        transition: transform .25s ease-in-out;
    }
    .menu.open {
        transform: translate3d(0vw, 0, 0);
    }
    .menu.closed {
        transform: translate3d(-100vw, 0, 0);
    }
    .menu .closeButton {
        width: 48px;
        padding-top: 48px;
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        border: none;
        border-radius: 50%;
        background-image: url('images/close_btn_48x48.svg');
        background-color: transparent;
        height: 0;
    }
    .menu h3 {
        font-size: 24pt;
        margin: 54px 20px 30px 20px;
    }
    .menu ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    .menu li {
        margin: 0 20px 0 20px;
        padding: 5px 0;
        border-bottom: solid 1px #efefef;
        box-sizing: border-box;
    }
    .menu li:last-child {
        border-bottom: none;
    }
    .menu li.current .link {
        color: #074275;
    }
    .menu li .link {
        display: block;
        height: 48px;
        padding-left: 54px;
        background-position: center left;
        background-size: 42px 42px;
        background-repeat: no-repeat;
        line-height: 48px;
        text-decoration: none;
        color: #333;
    }
    .menu li .link::first-letter {
        text-transform: uppercase;
    }
    .menu .harmonogram .link {
        background-image: url('images/ico_schedule_135.svg');
    }
    .menu .message .link {
        background-image: url('images/ico_message_135.svg');
    }
    .menu .helpline .link {
        background-image: url('images/ico_director_135.svg');
    }
    .menu .file .link {
        background-image: url('images/ico_download_135.svg');
    }
    .menu .loan .link {
        background-image: url('images/ico_loans_135.svg');
    }
    .menu .profile .link {
        background-image: url('images/ico_profile_135.svg');
    }
    .menu .reference .link {
        background-image: url('images/ico_fee_gold_145.svg');
    }
    .menu .service .link {
        background-image: url('images/ico_servis_135.svg');
    }

    .breadcrumbs {
        margin-top: 10px;
    }
    .breadcrumbs ul {
        display: flex;
        flex-direction: row;
        font-size: 1.5em;
    }
    .breadcrumbs li:nth-last-child(n+3) {
        display: none;
    }
    .breadcrumbs li:nth-last-child(2) a {
        width: 32px;
        display: inline-block;
        height: 0;
        padding-top: 32px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('images/arrow_back.svg');
    }
    /* .breadcrumbs {
        display: none;
    } */
    .content {
        /* position: relative; */
        /* top: 48px; */
        padding: 48px 10px 40px 10px;
    }
    .address {
        display: none;
    }

    .content h2 {
        display: none;
    }
}
