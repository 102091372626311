@import url(https://fonts.googleapis.com/css?family=Oxygen&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oxygen&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /* text-align: center; */
  font-family: 'Oxygen',Calibri,Helvetica,Arial,sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.User_messageBox__2EZYu p {
    color: red;
}
.User_messageBox__2EZYu.User_negative__3IGJD p {
    color: red;
}
.User_messageBox__2EZYu.User_pozitive__2Pg5C p {
    color: green;
}


.User_loginPage__2pNVC {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.User_login__2gZMP {
    width: 300px;
    padding: 0;
    margin: 0;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.User_login__2gZMP .User_messageBoxContainer__2DAzq {
    width: 100%;
    margin-bottom: 10px;
}
.User_login__2gZMP .User_loginForm__3aw0G {
    width: 300px;
    background-color: #fafafa;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20px;
    border-radius: 3px;
    box-sizing: border-box;
}
.User_login__2gZMP .User_loginForm__3aw0G input {
    width: 100%;
    outline: none;
    -webkit-appearance: none;
            appearance: none;
    padding: 8px 3px;
    border-radius: 3px;
    border: none;
    font-size: 12pt;
    line-height: 1.7em;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: #ffffff;
    margin-top: 3px;
}
.User_login__2gZMP .User_loginForm__3aw0G input:valid {
    padding-right: 27px;
    background-image: url(/static/media/valid.af2f90f8.svg);
    background-position: right 3px center;
    background-repeat: no-repeat;
}
.User_login__2gZMP .User_loginForm__3aw0G button {
    -webkit-align-self: center;
            align-self: center;
    margin-top: 10px;
}
.User_login__2gZMP .User_loginForm__3aw0G .User_activityIndicatorContainer__2Wn5y {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: calc(14pt * 1.4 + 10px);
}
.User_login__2gZMP .User_loginForm__3aw0G .User_button__1Sq6C {
    -webkit-appearance: none;
            appearance: none;
    border: none;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    background-color: #0b6fc7;
    color: #ffffff;
    outline: none;
}
.User_login__2gZMP .User_loginForm__3aw0G .User_button__1Sq6C:hover {
    background-color: red;
}

.User_passwordRequestPage__1rqyM {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.User_passwordRequestGenerated__3UoyU {
    width: 100%;
    max-width: 300px;
    padding: 0;
    margin: 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-sizing: border-box;
}
.User_passwordRequestGenerated__3UoyU button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.User_passwordRequestGenerated__3UoyU button:hover {
    background-color: red;
}
.User_passwordRequestGenerated__3UoyU .User_welcome__1WtEk,
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ {
    background-color: #f0f8ff;
    border-radius: 5px;
    padding: 10px;
}
.User_passwordRequestGenerated__3UoyU .User_welcome__1WtEk {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.User_passwordRequestGenerated__3UoyU .User_welcome__1WtEk .User_generate__1KTAp {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.User_passwordRequestGenerated__3UoyU .User_welcome__1WtEk .User_generate__1KTAp button {
    -webkit-align-self: center;
            align-self: center;
}
.User_passwordRequestGenerated__3UoyU .User_welcome__1WtEk .User_generate__1KTAp .User_activityIndicatorContainer__2Wn5y {
    -webkit-align-self: center;
            align-self: center;
}
.User_passwordRequestGenerated__3UoyU .User_description__3oaM3 {
    margin-bottom: 10px;
}
.User_passwordRequestGenerated__3UoyU .User_description__3oaM3 p {
    padding: 0;
    margin: 0;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ .User_description__3oaM3 {
    margin-top: 10px;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ .User_password__1qJyy {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ .User_password__1qJyy label {
    display: grid;
    grid-template-columns: 1fr, -webkit-max-content;
    grid-template-columns: 1fr, max-content;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 10px;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ .User_password__1qJyy label input {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    box-sizing: border-box;

    outline: none;
    -webkit-appearance: none;
            appearance: none;
    padding: 3px;
    border-radius: 3px;
    border: none;
    font-size: 12pt;
    line-height: 1.7em;
    box-sizing: border-box;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ .User_password__1qJyy label button {
    grid-column: 2;
    grid-row: 1;
    background-color: lightgray;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ .User_password__1qJyy label button:hover {
    background-color: red;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ .User_control__1PJK6 {
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.User_passwordRequestGenerated__3UoyU .User_result__1fJW_ .User_control__1PJK6 button {
    -webkit-align-self: center;
            align-self: center;
}

.Common_activityIndicator__1hq0W {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0,0,0,0.5);
}

/**
 * localActivityIndicator
 */
.Common_localActivityIndicator__3Aix- {
    display: inline-block;
}
.Common_localActivityIndicator__3Aix-:after {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    content: " ";
    display: block;
    border-radius: 50%;
    border-style: solid;
    border-color: rgb(50, 113, 230) transparent rgb(50, 113, 230) transparent;
    -webkit-animation: Common_localActivityIndicator__3Aix- 1.2s linear infinite;
            animation: Common_localActivityIndicator__3Aix- 1.2s linear infinite;
}
@-webkit-keyframes Common_localActivityIndicator__3Aix- {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes Common_localActivityIndicator__3Aix- {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.Common_localActivityIndicatorSmall__3-i3T {
    width: 32px;
    height: 32px;
    padding: 3px;
}
.Common_localActivityIndicatorSmall__3-i3T:after {
    border-width: 3px;
}
.Common_localActivityIndicatorMedium__2kGk2 {
    width: 48px;
    height: 48px;
    padding: 4px;
}
.Common_localActivityIndicatorMedium__2kGk2:after {
    border-width: 5px;
}
.Common_localActivityIndicatorBig__3TjgZ {
    width: 64px;
    height: 64px;
    padding: 6px;
}
.Common_localActivityIndicatorBig__3TjgZ:after {
    border-width: 6px;
}

.Common_localProgressBar__ec2VR {
    width: 100px;
    background-color: rgb(194, 194, 194);
}
.Common_localProgressBar__ec2VR > div {
    height: 100%;
    background-color: rgb(50, 113, 230);
}
.Common_localProgressBar__ec2VR.Common_small__1ufs_ {
    height: 10px;
}
.Common_localProgressBar__ec2VR.Common_medium__3ujz6 {
    height: 20px;
}
.Common_localProgressBar__ec2VR.Common_big__2Ip_Z {
    height: 30px;
}


/**
 * Page
 */
 
 
a, a:visited {
     text-decoration: none;
     color: #0b6fc7;
     cursor: pointer;
}
a, a:visited, a:hover {
    text-decoration: none;
}
h1, h2, h3, h4 {
    font-weight: normal;
}
button {
    padding: 0;
    margin: 0;
    cursor: pointer;
}
ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

body {
    padding: 0;
    margin: 0;
    font-family: 'Oxygen',Calibri,Helvetica,Arial,sans-serif;
}
.Common_page__olevy {
    width: 100%;
    padding: 0;
    margin: 0;
    display: grid;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}
.Common_logo__1PbLX {
    height: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    overflow: hidden;
    display: block;
}
.Common_breadcrumbs__BoeaS {
    /* background-color: beige; */
}
.Common_content__15ptC {
    /* background-color: crimson; */
}
.Common_user__2AvCM {
    /* background-color: yellow; */
}
.Common_menu__2fQZe {
    /* background-color: #ebecec; */
}

@media only screen and (min-width: 768px) {
    .Common_header__riqkl {
        height: 126px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .Common_logo__1PbLX {
        width: 176px;
        background-image: url(/static/media/logo_poz_176x126.3fb7e88f.svg);
        padding-top: 126px;
    }
    .Common_title__3Jbuy {
        width: 232px;
        height: 126px;
        background-color: #ebecec;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
    }
    .Common_title__3Jbuy h1 {
        font-size: 13pt;
        margin: 0;
        padding: 0;
    }
    .Common_user__2AvCM {
        box-sizing: border-box;
        -webkit-flex: 1 1;
                flex: 1 1;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding: 10px 10px;
    }
    .Common_user__2AvCM ul {
        display: grid;
        grid-template-columns: -webkit-max-content -webkit-max-content;
        grid-template-columns: max-content max-content;
        grid-gap: 10px;
        gap: 10px;
    }
    .Common_user__2AvCM ul li {
        display: contents;
    }
    .Common_user__2AvCM ul li span.Common_item__32Ij8::after {
        content: ':';
    }
    .Common_control__2dgoY {
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
    }
    .Common_control__2dgoY .Common_logoutButton__1cs1m {
        border: none;
        background-color: transparent;
        color: #0b6fc7;
        font-size: 12pt;
        cursor: pointer;
    }
    .Common_breadcrumbs__BoeaS ul {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-left: 20px;
        grid-gap: 10px;
        gap: 10px;
    }
    .Common_breadcrumbs__BoeaS ul li {
        line-height: 48px;
        padding-right: 20px;
        background-image: url(/static/media/navi_separator.94206520.svg);
        background-position: right center;
        background-repeat: no-repeat;
        text-transform: uppercase;
    }
    .Common_breadcrumbs__BoeaS ul li:last-child {
        background-image: none;
    }
    .Common_content__15ptC {
        margin: 0 0 20px 0;
    }
    .Common_menuButton__2UWzN {
        display: none;
    }
    .Common_menu__2fQZe {
        background-color: #ebecec;
    }
    .Common_menu__2fQZe .Common_closeButton__X_q3o {
        display: none;
    }
    .Common_menu__2fQZe h3 {
        display: none;
    }
    .Common_menu__2fQZe ul {
        -webkit-columns: 3;
                columns: 3;
        padding: 10px 20px;
    }
    .Common_menu__2fQZe ul li {
        line-height: 40px;
    }
    .Common_menu__2fQZe ul li.Common_current__2jX1T .Common_link__2D__V {
        color: #074275;
    }
    .Common_menu__2fQZe ul li .Common_link__2D__V {
        display: inline-block;
    }
    .Common_menu__2fQZe ul li .Common_link__2D__V::first-letter {
        text-transform: uppercase;
    }
    .Common_topButton__ooHqv {
        display: none;
    }
    .Common_userButton__2JLDh {
        display: none;
    }
    .Common_address__2RbR6 {
        margin: 15px 15px 20px 15px;
    }
}
@media only screen and (max-width: 767px) {
    .Common_header__riqkl {
        height: 48px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
        background-color: whitesmoke;
    }
    .Common_logo__1PbLX {
        position: absolute;
        left: 0;
        top: 0;
        width: 95px;
        background-image: url(/static/media/logo_poz_95x48.196ee73e.svg);
        padding-top: 48px;
    }
    .Common_title__3Jbuy {
        display: none;
    }
    .Common_user__2AvCM {
        display: none;
    }
    .Common_control__2dgoY {
        display: none;
    }
    .Common_menuButton__2UWzN {
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: border-box;
        -webkit-appearance: none;
                appearance: none;
        width: 48px;
        height: 48px;
        border: none;
        background-image: url(/static/media/menu_btn_48x48.61d43e29.svg);
        overflow: hidden;
        padding-top: 48px;
        background-color: transparent;
        border-radius: 50%;
    }
    .Common_userButton__2JLDh {
        display: none;
        /* position: absolute;
        top: 0;
        right: 48px;
        box-sizing: border-box;
        appearance: none;
        width: 48px;
        height: 48px;
        border: none;
        background-image: url('images/user_btn_48x48.svg');
        overflow: hidden;
        padding-top: 48px;
        background-color: transparent;
        border-radius: 50%; */
    }
    .Common_topButton__ooHqv {
        position: fixed;
        right: 10px;
        bottom: 10px;
        z-index: 10;
        width: 48px;
        height: 0;
        padding-top: 48px;
        overflow: hidden;
        border: none;
        border-radius: 50%;
        background-color: rgba(100,100,100,0.5);
        background-image: url(/static/media/top_btn_48x48.b1c5590d.svg);
    }
    .Common_topButton__ooHqv.Common_invisible__NAS22 {
        display: none;
    }
    .Common_menu__2fQZe {
        background-color: rgba(255,255,255,1);
        z-index: 11;
        overflow: scroll;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        transition: -webkit-transform .25s ease-in-out;
        transition: transform .25s ease-in-out;
        transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
    }
    .Common_menu__2fQZe.Common_open__XHC6f {
        -webkit-transform: translate3d(0vw, 0, 0);
                transform: translate3d(0vw, 0, 0);
    }
    .Common_menu__2fQZe.Common_closed__2Ibfb {
        -webkit-transform: translate3d(-100vw, 0, 0);
                transform: translate3d(-100vw, 0, 0);
    }
    .Common_menu__2fQZe .Common_closeButton__X_q3o {
        width: 48px;
        padding-top: 48px;
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        border: none;
        border-radius: 50%;
        background-image: url(/static/media/close_btn_48x48.78a4f65f.svg);
        background-color: transparent;
        height: 0;
    }
    .Common_menu__2fQZe h3 {
        font-size: 24pt;
        margin: 54px 20px 30px 20px;
    }
    .Common_menu__2fQZe ul {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 0;
        padding: 0;
    }
    .Common_menu__2fQZe li {
        margin: 0 20px 0 20px;
        padding: 5px 0;
        border-bottom: solid 1px #efefef;
        box-sizing: border-box;
    }
    .Common_menu__2fQZe li:last-child {
        border-bottom: none;
    }
    .Common_menu__2fQZe li.Common_current__2jX1T .Common_link__2D__V {
        color: #074275;
    }
    .Common_menu__2fQZe li .Common_link__2D__V {
        display: block;
        height: 48px;
        padding-left: 54px;
        background-position: center left;
        background-size: 42px 42px;
        background-repeat: no-repeat;
        line-height: 48px;
        text-decoration: none;
        color: #333;
    }
    .Common_menu__2fQZe li .Common_link__2D__V::first-letter {
        text-transform: uppercase;
    }
    .Common_menu__2fQZe .Common_harmonogram__aLPmF .Common_link__2D__V {
        background-image: url(/static/media/ico_schedule_135.80665bc2.svg);
    }
    .Common_menu__2fQZe .Common_message__ojMtQ .Common_link__2D__V {
        background-image: url(/static/media/ico_message_135.aeb42711.svg);
    }
    .Common_menu__2fQZe .Common_helpline__2Q3PH .Common_link__2D__V {
        background-image: url(/static/media/ico_director_135.933b40e1.svg);
    }
    .Common_menu__2fQZe .Common_file__39TjX .Common_link__2D__V {
        background-image: url(/static/media/ico_download_135.72725852.svg);
    }
    .Common_menu__2fQZe .Common_loan__3WKiN .Common_link__2D__V {
        background-image: url(/static/media/ico_loans_135.a66b2db7.svg);
    }
    .Common_menu__2fQZe .Common_profile__3OL5o .Common_link__2D__V {
        background-image: url(/static/media/ico_profile_135.537452c9.svg);
    }
    .Common_menu__2fQZe .Common_reference__1m03p .Common_link__2D__V {
        background-image: url(/static/media/ico_fee_gold_145.c797ec1f.svg);
    }
    .Common_menu__2fQZe .Common_service__3RS1g .Common_link__2D__V {
        background-image: url(/static/media/ico_servis_135.ad8905e4.svg);
    }

    .Common_breadcrumbs__BoeaS {
        margin-top: 10px;
    }
    .Common_breadcrumbs__BoeaS ul {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        font-size: 1.5em;
    }
    .Common_breadcrumbs__BoeaS li:nth-last-child(n+3) {
        display: none;
    }
    .Common_breadcrumbs__BoeaS li:nth-last-child(2) a {
        width: 32px;
        display: inline-block;
        height: 0;
        padding-top: 32px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(/static/media/arrow_back.1451f237.svg);
    }
    /* .breadcrumbs {
        display: none;
    } */
    .Common_content__15ptC {
        /* position: relative; */
        /* top: 48px; */
        padding: 48px 10px 40px 10px;
    }
    .Common_address__2RbR6 {
        display: none;
    }

    .Common_content__15ptC h2 {
        display: none;
    }
}

.Harmonogram_harmonogram__3UitP .Harmonogram_activityIndicatorContainer__2XLhf {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.Harmonogram_harmonogram__3UitP button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.Harmonogram_harmonogram__3UitP button:hover {
    background-color: red;
}

.Harmonogram_harmonogram__3UitP .Harmonogram_messageBox__1Ni5w p {
    color: red;
}
.Harmonogram_harmonogram__3UitP .Harmonogram_messageBox__1Ni5w.Harmonogram_negative__3BNGh p {
    color: red;
}
.Harmonogram_harmonogram__3UitP .Harmonogram_messageBox__1Ni5w.Harmonogram_pozitive__sHfiB p {
    color: green;
}
.Harmonogram_harmonogram__3UitP .Harmonogram_messageBox__1Ni5w button {
    background-color: lightgray;
}
.Harmonogram_harmonogram__3UitP .Harmonogram_messageBox__1Ni5w button:hover {
    background-color: red;
}

@media only screen and (min-width: 768px) {
    .Harmonogram_harmonogram__3UitP > .Harmonogram_layout__2gEV5 {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    .Harmonogram_harmonogramHarmonogram__1u1AM {
        grid-column: 1;
        padding-right: 40px;
    }
    .Harmonogram_harmonogramSummary__2j82p {
        grid-column: 2;
    }
}

.Harmonogram_harmonogramStateList__31ul9 {
    margin: 30px 0 20px 0;
    list-style: none;
    display: table;
    border-collapse: collapse;
}
.Harmonogram_harmonogramStateList__31ul9 > li {
    display: contents;
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_current__2reX5 {
    font-size: 14pt;
}
.Harmonogram_harmonogramStateList__31ul9 > li:last-child > .Harmonogram_link__1G6QN {
    border-bottom: none;
}
.Harmonogram_harmonogramStateList__31ul9 > li > .Harmonogram_link__1G6QN {
    display: table-row;
    border-bottom: solid 1px #c0c0c0;
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_client__1WaCq.Harmonogram_current__2reX5 > .Harmonogram_link__1G6QN {
    background-color: #d1f1dc;
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_company__2veLX.Harmonogram_current__2reX5 > .Harmonogram_link__1G6QN {
    background-color: #e4f1fe;
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_client__1WaCq.Harmonogram_finished__3T76G > .Harmonogram_link__1G6QN .Harmonogram_date__1OIRc {
    background-image: url(/static/media/state_client_finished.f6fe7987.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_client__1WaCq.Harmonogram_current__2reX5 > .Harmonogram_link__1G6QN .Harmonogram_date__1OIRc {
    background-image: url(/static/media/state_client_current.0213d839.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_client__1WaCq.Harmonogram_future__2-LIS > .Harmonogram_link__1G6QN .Harmonogram_date__1OIRc {
    background-image: url(/static/media/state_client_future.00608a4f.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_company__2veLX.Harmonogram_finished__3T76G > .Harmonogram_link__1G6QN .Harmonogram_date__1OIRc {
    background-image: url(/static/media/state_company_finished.bd3b9178.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_company__2veLX.Harmonogram_current__2reX5 > .Harmonogram_link__1G6QN .Harmonogram_date__1OIRc {
    background-image: url(/static/media/state_company_current.5ab84406.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_company__2veLX.Harmonogram_future__2-LIS > .Harmonogram_link__1G6QN .Harmonogram_date__1OIRc {
    background-image: url(/static/media/state_company_future.31716c46.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_finished__3T76G > .Harmonogram_link__1G6QN {
    color: #b0b0b0;
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_future__2-LIS .Harmonogram_link__1G6QN {
    color: #333333;
}
.Harmonogram_harmonogramStateList__31ul9 > li:is(.Harmonogram_finished__3T76G,.Harmonogram_current__2reX5,.Harmonogram_future__2-LIS) > a:hover {
    background-color: #fffbeb;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_date__1OIRc {
    display: table-cell;
    padding: 5px 10px 5px 47px;
    text-align: center;
    background-position: 5px 5px;
    background-repeat: no-repeat;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_content__3T-sR {
    display: table-cell;
    padding: 5px 29px 5px 5px;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url(/static/media/arrow.e9b52878.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_name__3GJit {
    display: block;
    padding: 5px;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 5px;
    font-size: 10pt;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er > * {
    margin-right: 5px;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er .Harmonogram_delay__3hkpg {
    padding: 2px 8px;
    color: #ffffff;
    border-radius: 2px;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er .Harmonogram_delay__3hkpg.Harmonogram_company__2veLX {
    background-color: #0b6fc7;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er .Harmonogram_delay__3hkpg.Harmonogram_client__1WaCq {
    background-color: #0bc74c;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er .Harmonogram_delay__3hkpg.Harmonogram_god__12aKA {
    background-color: #e0c215;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er .Harmonogram_icon__1TuF6 {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er .Harmonogram_icon__1TuF6.Harmonogram_clima__24Afj {
    background-image: url(/static/media/icon_clima.b1005e33.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li .Harmonogram_properties__Vi6er .Harmonogram_icon__1TuF6.Harmonogram_hurdle__YJJIH {
    background-image: url(/static/media/icon_hurdle.78bbb14c.svg);
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_summary__QHUaz {
    display: table-row;
    color: #803300;
    font-size: 14pt;
}
.Harmonogram_harmonogramStateList__31ul9 > li.Harmonogram_summary__QHUaz .Harmonogram_date__1OIRc strong {
    background-color: #ffe6d5;
    padding: 0 5px;
}


.Harmonogram_harmonogramSummary__2j82p h2 {
    margin-bottom: 15px;
}
.Harmonogram_harmonogramSummary__2j82p > div h3 {
    margin-bottom: 10px;
}
.Harmonogram_harmonogramSummary__2j82p > div ul {
    padding: 10px;
    border-radius: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 20px;
}
.Harmonogram_harmonogramSummary__2j82p > div ul li {
    margin: 5px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.Harmonogram_harmonogramSummary__2j82p > div ul li.Harmonogram_summary__QHUaz {
    font-weight: bold;
}
.Harmonogram_harmonogramSummary__2j82p > div.Harmonogram_client__1WaCq {
    color: #077b2f;
}
.Harmonogram_harmonogramSummary__2j82p > div.Harmonogram_client__1WaCq ul {
    background-color: #d1f1dc;
}
.Harmonogram_harmonogramSummary__2j82p > div.Harmonogram_company__2veLX {
    color: #09589e;
}
.Harmonogram_harmonogramSummary__2j82p > div.Harmonogram_company__2veLX ul {
    background-color: #d1e5f6;
}
.Harmonogram_harmonogramSummary__2j82p > div.Harmonogram_god__12aKA {
    color: #87750d;
}
.Harmonogram_harmonogramSummary__2j82p > div.Harmonogram_god__12aKA ul {
    background-color: #fff6d5;
}


.Harmonogram_harmonogramNoticeList__s5jo_ .Harmonogram_notice__1RA7L {
    border-radius: 5px;
    background-color: #fff6d5;
    color: #554400;
    padding: 10px;
    margin: 15px 0;
}
.Harmonogram_harmonogramNoticeList__s5jo_ .Harmonogram_notice__1RA7L h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: left center;
}
.Harmonogram_harmonogramNoticeList__s5jo_ .Harmonogram_notice__1RA7L.Harmonogram_hurdle__YJJIH h3 {
    background-image: url(/static/media/notice_hurdle.3b99bd01.svg);
}
.Harmonogram_harmonogramNoticeList__s5jo_ .Harmonogram_notice__1RA7L.Harmonogram_cooperation__2J9Lz h3 {
    background-image: url(/static/media/notice_cooperation.40d0c02b.svg);
}
.Harmonogram_harmonogramNoticeList__s5jo_ .Harmonogram_notice__1RA7L.Harmonogram_clima__24Afj h3 {
    background-image: url(/static/media/notice_clima.e4e78b0a.svg);
}
.Harmonogram_harmonogramNoticeList__s5jo_ .Harmonogram_notice__1RA7L p {
    font-size: 10pt;
}


.Harmonogram_state__1zf3G .Harmonogram_activityIndicatorContainer__2XLhf {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.Harmonogram_state__1zf3G button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.Harmonogram_state__1zf3G button:hover {
    background-color: red;
}

.Harmonogram_state__1zf3G .Harmonogram_messageBox__1Ni5w p {
    color: red;
}
.Harmonogram_state__1zf3G .Harmonogram_messageBox__1Ni5w.Harmonogram_negative__3BNGh p {
    color: red;
}
.Harmonogram_state__1zf3G .Harmonogram_messageBox__1Ni5w.Harmonogram_pozitive__sHfiB p {
    color: green;
}
.Harmonogram_state__1zf3G .Harmonogram_messageBox__1Ni5w button {
    background-color: lightgray;
}
.Harmonogram_state__1zf3G .Harmonogram_messageBox__1Ni5w button:hover {
    background-color: red;
}

.Harmonogram_state__1zf3G .Harmonogram_name__3GJit {
    padding-left: 36px;
    background-position: left center;
    background-repeat: no-repeat;
    font-size: 14pt;
    line-height: 32px;
    margin-top: 30px;
    margin-bottom: 20px;
}
.Harmonogram_state__1zf3G .Harmonogram_name__3GJit.Harmonogram_company__2veLX.Harmonogram_finished__3T76G {
    background-image: url(/static/media/state_company_finished.bd3b9178.svg);
}
.Harmonogram_state__1zf3G .Harmonogram_name__3GJit.Harmonogram_company__2veLX.Harmonogram_current__2reX5 {
    background-image: url(/static/media/state_company_current.5ab84406.svg);
}
.Harmonogram_state__1zf3G .Harmonogram_name__3GJit.Harmonogram_company__2veLX.Harmonogram_future__2-LIS {
    background-image: url(/static/media/state_company_future.31716c46.svg);
}
.Harmonogram_state__1zf3G .Harmonogram_name__3GJit.Harmonogram_client__1WaCq.Harmonogram_finished__3T76G {
    background-image: url(/static/media/state_client_finished.f6fe7987.svg);
}
.Harmonogram_state__1zf3G .Harmonogram_name__3GJit.Harmonogram_client__1WaCq.Harmonogram_current__2reX5 {
    background-image: url(/static/media/state_client_current.0213d839.svg);
}
.Harmonogram_state__1zf3G .Harmonogram_name__3GJit.Harmonogram_client__1WaCq.Harmonogram_future__2-LIS {
    background-image: url(/static/media/state_client_future.00608a4f.svg);
}

.Harmonogram_state__1zf3G .Harmonogram_notice__1RA7L {
    border-radius: 5px;
    background-color: #fff6d5;
    color: #554400;
    padding: 10px;
    margin: 15px 0;
}
.Harmonogram_state__1zf3G .Harmonogram_notice__1RA7L h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: left center;
}
.Harmonogram_state__1zf3G .Harmonogram_notice__1RA7L.Harmonogram_hurdle__YJJIH h3 {
    background-image: url(/static/media/notice_hurdle.3b99bd01.svg);
}
.Harmonogram_state__1zf3G .Harmonogram_notice__1RA7L.Harmonogram_cooperation__2J9Lz h3 {
    background-image: url(/static/media/notice_cooperation.40d0c02b.svg);
}
.Harmonogram_state__1zf3G .Harmonogram_notice__1RA7L.Harmonogram_clima__24Afj h3 {
    background-image: url(/static/media/notice_clima.e4e78b0a.svg);
}
.Harmonogram_state__1zf3G .Harmonogram_notice__1RA7L p {
    font-size: 10pt;
}

.Harmonogram_state__1zf3G .Harmonogram_time__3dlh7 {
    margin-top: 30px;
    margin-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.Harmonogram_state__1zf3G .Harmonogram_time__3dlh7 > * {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.Harmonogram_state__1zf3G .Harmonogram_time__3dlh7 > .Harmonogram_end__2tG2Q {
    border-left: solid 1px #000000;
}
.Harmonogram_state__1zf3G .Harmonogram_time__3dlh7 .Harmonogram_date__1OIRc {
    font-size: 16pt;
    color: #085294;
    margin-bottom: 10px;
}
.Harmonogram_state__1zf3G .Harmonogram_time__3dlh7 > .Harmonogram_end__2tG2Q.Harmonogram_delay__3hkpg {
    color: #803300;
}

.Harmonogram_state__1zf3G .Harmonogram_log__2CpvI {
    margin-top: 40px;
}
.Harmonogram_state__1zf3G .Harmonogram_log__2CpvI > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
}
.Harmonogram_state__1zf3G .Harmonogram_log__2CpvI > p {
    font-size: 11pt;
}

.Harmonogram_state__1zf3G .Harmonogram_hurdleList__Y2y5o {
    margin-top: 40px;
}
.Harmonogram_state__1zf3G .Harmonogram_hurdleList__Y2y5o > h3 {
    font-size: 12pt;
    font-weight: bold;
}
.Harmonogram_state__1zf3G .Harmonogram_hurdleList__Y2y5o > ul {
    margin-top: 15px;
}
.Harmonogram_state__1zf3G .Harmonogram_hurdleList__Y2y5o > ul > li {
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 10px;
}
.Harmonogram_state__1zf3G .Harmonogram_hurdleList__Y2y5o > ul > li > span {
    font-weight: bold;
}
.Harmonogram_state__1zf3G .Harmonogram_hurdleList__Y2y5o > ul > li > p {
    margin-top: 10px;
}
.Harmonogram_state__1zf3G .Harmonogram_hurdleList__Y2y5o .Harmonogram_end__2tG2Q::before {
    content: ' - ';
}

.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg {
    margin-top: 40px;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div {
    margin-top: 20px;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div > .Harmonogram_length__1E7us {
    padding: 2px 8px;
    color: #ffffff;
    border-radius: 2px;
    margin-right: 5px;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div > .Harmonogram_title__2LtI2 {
    padding: 2px;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div.Harmonogram_company__2veLX > .Harmonogram_length__1E7us {
    background-color: #0b6fc7;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div.Harmonogram_company__2veLX > .Harmonogram_title__2LtI2 {
    color: #0b6fc7;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div.Harmonogram_client__1WaCq > .Harmonogram_length__1E7us {
    background-color: #0bc74c;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div.Harmonogram_client__1WaCq > .Harmonogram_title__2LtI2 {
    color: #0bc74c;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div.Harmonogram_god__12aKA > .Harmonogram_length__1E7us {
    background-color: #e0c215;
}
.Harmonogram_state__1zf3G .Harmonogram_delay__3hkpg > div.Harmonogram_god__12aKA > .Harmonogram_title__2LtI2 {
    color: #e0c215;
}

.Harmonogram_state__1zf3G .Harmonogram_description__38ZgP {
    margin-top: 40px;
}
.Harmonogram_state__1zf3G .Harmonogram_description__38ZgP > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
}
.Harmonogram_state__1zf3G .Harmonogram_description__38ZgP > p {
    font-size: 11pt;
    white-space: pre-wrap;
}

.Helpline_helpline__2SmCQ button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.Helpline_helpline__2SmCQ button:hover {
    background-color: red;
}

.Helpline_helpline__2SmCQ .Helpline_messageBox__3NUx4 p {
    color: red;
}
.Helpline_helpline__2SmCQ .Helpline_messageBox__3NUx4.Helpline_negative__17LoT p {
    color: red;
}
.Helpline_helpline__2SmCQ .Helpline_messageBox__3NUx4.Helpline_pozitive__28hhd p {
    color: green;
}
.Helpline_helpline__2SmCQ .Helpline_messageBox__3NUx4 button {
    background-color: lightgray;
}
.Helpline_helpline__2SmCQ .Helpline_messageBox__3NUx4 button:hover {
    background-color: red;
}

.Helpline_helpline__2SmCQ .Helpline_activityIndicatorContainer__1hq7P {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Helpline_helpline__2SmCQ .Helpline_layout__15XEh {
    position: relative;
    top: 0;
    left: 0;
}
.Helpline_helpline__2SmCQ .Helpline_layout__15XEh .Helpline_activityIndicatorContainer__1hq7P {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,1);
}
.Helpline_helpline__2SmCQ .Helpline_layout__15XEh .Helpline_title__2E9wM {
    font-size: 12pt;
}
.Helpline_helpline__2SmCQ .Helpline_layout__15XEh textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    min-height: 200px;
    font-size: 11pt;
    font-family: inherit;
}
.Helpline_helpline__2SmCQ .Helpline_newMessage__6VGJz img {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .Helpline_helpline__2SmCQ {
        padding-left: 10px;
        padding-right: 10px;
    }
    .Helpline_helpline__2SmCQ .Helpline_motivation__3F73H h3 {
        display: none;
    }
    .Helpline_helpline__2SmCQ .Helpline_newMessage__6VGJz {
        margin-top: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .Helpline_helpline__2SmCQ {
        display: grid;
        grid-template-columns: 408px 1fr;
        grid-gap: 20px;
        gap: 20px;
    }
    .Helpline_helpline__2SmCQ .Helpline_motivation__3F73H {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(/static/media/ico_director_135.933b40e1.svg);
    }
    .Helpline_helpline__2SmCQ .Helpline_newMessage__6VGJz {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
}
.File_file__1BRkL {
    position: relative;
    top: 0;
    left: 0;
}
.File_file__1BRkL .File_activityIndicatorContainer__2Dfz_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 80vh;
    background-color: rgba(240,240,240,1);
}


.File_fileFileList__1ge15 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin-top: 40px;
}

.File_fileFile__1eFEW {
    position: relative;
    top: 0;
    left: 0;
}
.File_fileFile__1eFEW:last-child .File_fileData__1WVZ5 {
    border-bottom: none;
}
.File_fileFile__1eFEW .File_fileData__1WVZ5:hover {
    background-color: rgba(240,240,240,1);
}
.File_fileFile__1eFEW .File_fileData__1WVZ5 {
    cursor: pointer;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 48px 48px;
    display: grid;
    grid-template-columns: 48px 1fr -webkit-max-content;
    grid-template-columns: 48px 1fr max-content;
    grid-template-rows: 24px;
    grid-gap: 5px;
    gap: 5px;
    padding: 20px 0;
    border-bottom: solid 1px lightgray;
    max-width: 100%;
}
.File_fileFile__1eFEW .File_fileData__1WVZ5.File_pdf__1Xu5R {
    background-image: url(/static/media/pdf-svgrepo-com.20a57c59.svg);
}
.File_fileFile__1eFEW .File_fileData__1WVZ5.File_jpg__3p9At {
    background-image: url(/static/media/jpg-svgrepo-com.46fc91df.svg);
}
.File_fileFile__1eFEW .File_fileData__1WVZ5.File_png__2xyQ0 {
    background-image: url(/static/media/png-svgrepo-com.602dad01.svg);
}
.File_fileFile__1eFEW .File_fileData__1WVZ5.File_doc__30mWM {
    background-image: url(/static/media/doc-svgrepo-com.3977eb0b.svg);
}
.File_fileFile__1eFEW .File_fileData__1WVZ5.File_xls__QShab {
    background-image: url(/static/media/xls-svgrepo-com.e9d28df0.svg);
}
.File_fileFile__1eFEW .File_fileData__1WVZ5.File_txt__2OmOT {
    background-image: url(/static/media/txt-svgrepo-com.f0b8f64a.svg);
}
.File_fileFile__1eFEW .File_fileData__1WVZ5.File_zip__vqw8q {
    background-image: url(/static/media/zip-svgrepo-com.047627e2.svg);
}
.File_fileFile__1eFEW .File_fileData__1WVZ5 span.File_name__1xLh4 {
    grid-column: 2;
    grid-row: 1 / span 2;
    align-self: center;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.File_fileFile__1eFEW .File_fileData__1WVZ5 span.File_type__1n2zl {
    display: none;
}
.File_fileFile__1eFEW .File_fileData__1WVZ5 span.File_size__1BhUe {
    grid-column: 3;
    grid-row: 1;
    color: #000;
    color: initial;
}
.File_fileFile__1eFEW .File_fileData__1WVZ5 span.File_date__JvG6C {
    grid-column: 3;
    grid-row: 2;
    color: #000;
    color: initial;
}
.File_fileFile__1eFEW .File_curtainContainer__1JiK9 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,0.5);
}
.File_fileFile__1eFEW .File_progressBarContainer__1b8ON {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,1);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

@media only screen and (max-width: 767px) {
    .File_file__1BRkL {
        padding-left: 10px;
        padding-right: 10px;
    }
    .File_file__1BRkL .File_motivation__3IDmj h3 {
        display: none;
    }
    .File_fileFileList__1ge15 {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .File_file__1BRkL {
        display: grid;
        grid-template-columns: 408px 1fr;
        grid-gap: 20px;
        gap: 20px;
    }
    .File_file__1BRkL .File_motivation__3IDmj {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(/static/media/ico_download_135.72725852.svg);
    }
    .File_file__1BRkL .File_layout__1FgiR {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
}
.Loan_loan__Uchmc .Loan_activityIndicatorContainer__OMHm2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.Loan_loan__Uchmc button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.Loan_loan__Uchmc button:hover {
    background-color: red;
}

.Loan_loan__Uchmc .Loan_messageBox__3tO6l p {
    color: red;
}
.Loan_loan__Uchmc .Loan_messageBox__3tO6l.Loan_negative__2MsRW p {
    color: red;
}
.Loan_loan__Uchmc .Loan_messageBox__3tO6l.Loan_pozitive__1y4p8 p {
    color: green;
}
.Loan_loan__Uchmc .Loan_messageBox__3tO6l button {
    background-color: lightgray;
}
.Loan_loan__Uchmc .Loan_messageBox__3tO6l button:hover {
    background-color: red;
}

.Loan_loanLoanList__PMt40 ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    grid-gap: 20px;
    gap: 20px;
}

.Loan_loanLoan__1Ol2H {
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 15px 20px;
    gap: 15px 20px;
    box-sizing: border-box;
    padding: 20px;
    /* margin: 10px; */
    border-radius: 5px;
    background-color: lightgrey;
}
.Loan_loanLoan__1Ol2H.Loan_paid__1Umxj {
    background-color: rgb(233, 233, 233);
}
.Loan_loanLoan__1Ol2H.Loan_expired__fxUsD {
    background-color: rgb(250, 207, 207);
}
.Loan_loanLoan__1Ol2H.Loan_running__21CwS {
    background-color: rgb(221, 250, 207);
}
.Loan_loanLoan__1Ol2H > span {
    font-size: 11pt;
    display: contents;
}
.Loan_loanLoan__1Ol2H > .Loan_number__1K-AM {
    font-weight: bold;
}
.Loan_loanLoan__1Ol2H span.Loan_value__1Lw_7 {
    text-align: right;
}
.Loan_loanLoan__1Ol2H.Loan_expired__fxUsD .Loan_currentAmount__2d7Q1 .Loan_value__1Lw_7 {
    font-weight: bold;
}


@media only screen and (max-width: 767px) {
    .Loan_loan__Uchmc {
        padding-left: 10px;
        padding-right: 10px;
    }
    .Loan_loan__Uchmc .Loan_motivation__1PHOH h3 {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .Loan_loan__Uchmc {
        display: grid;
        grid-template-columns: 408px 1fr;
        grid-gap: 20px;
        gap: 20px;
    }
    .Loan_loan__Uchmc .Loan_motivation__1PHOH {
        grid-column: 1;
        grid-row: 1 / span 2;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(/static/media/ico_loans_135.a66b2db7.svg);
    }
    .Loan_loan__Uchmc .Loan_layout__1Cp2D {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
}
.Reference_reference__3FvJn .Reference_activityIndicatorContainer__1wblq {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(240,240,240,1);
}
.Reference_reference__3FvJn > .Reference_activityIndicatorContainer__1wblq {
    height: 80vh;
}
.Reference_reference__3FvJn button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.Reference_reference__3FvJn .Reference_messageBox__mEnzY p {
    color: red;
}
.Reference_reference__3FvJn .Reference_messageBox__mEnzY.Reference_negative__25bjk p {
    color: red;
}
.Reference_reference__3FvJn .Reference_messageBox__mEnzY.Reference_pozitive__2N9Vc p {
    color: green;
}
.Reference_reference__3FvJn .Reference_messageBox__mEnzY button {
    background-color: lightgray;

}
.Reference_reference__3FvJn .Reference_messageBox__mEnzY button:hover {
    background-color: red;
}

.Reference_referenceNewReference__3jFKk button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.Reference_referenceNewReference__3jFKk button:hover {
    background-color: red;
}
.Reference_referenceNewReference__3jFKk .Reference_referenceForm__1GvPq {
    position: relative;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 400px;
}
.Reference_referenceNewReference__3jFKk .Reference_referenceForm__1GvPq form {
    display: contents;
}
.Reference_referenceNewReference__3jFKk .Reference_referenceForm__1GvPq form label {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.Reference_referenceNewReference__3jFKk .Reference_referenceForm__1GvPq input:not([type="submit"]) {
    outline: none;
    -webkit-appearance: none;
            appearance: none;
    padding: 3px;
    border-radius: 3px;
    border: solid 1px #cccccc;
    font-size: 12pt;
    line-height: 1.7em;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 3px;
}
.Reference_referenceNewReference__3jFKk .Reference_referenceForm__1GvPq input:not([type="submit"]):valid {
    padding-right: 27px;
    background-image: url(/static/media/valid.af2f90f8.svg);
    background-position: right 3px center;
    background-repeat: no-repeat;
}
.Reference_referenceNewReference__3jFKk input[type="submit"] {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.Reference_referenceNewReference__3jFKk input[type="submit"]:hover {
    background-color: red;
}
.Reference_referenceNewReference__3jFKk .Reference_activityIndicatorContainer__1wblq {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,1);
}
.Reference_referenceNewReference__3jFKk .Reference_cancel__3I17R {
    background-color: lightgray;
}

.Reference_referenceReferenceList__3Eb-g ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    grid-gap: 20px;
    gap: 20px;
}
.Reference_referenceReferenceList__3Eb-g .Reference_activityIndicatorContainer__1wblq {
    height: 80vh;
}

.Reference_referenceReference__1_GOD {
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 15px 20px;
    gap: 15px 20px;
    box-sizing: border-box;
    padding: 20px;
    /* margin: 10px; */
    border-radius: 5px;
    background-color: rgb(245, 245, 245);
}
.Reference_referenceReference__1_GOD.Reference_paid__2x0ke {
    background-color: lightgrey;
}
.Reference_referenceReference__1_GOD.Reference_expired__ud9v5 {
    background-color: rgb(250, 207, 207);
}
.Reference_referenceReference__1_GOD.Reference_running__geUN1 {
    background-color: rgb(221, 250, 207);
}
.Reference_referenceReference__1_GOD > span {
    font-size: 11pt;
    display: contents;
}
.Reference_referenceReference__1_GOD > .Reference_number__3HlAy {
    font-weight: bold;
}
.Reference_referenceReference__1_GOD span.Reference_value__1myau {
    text-align: right;
}
.Reference_referenceReference__1_GOD.Reference_expired__ud9v5 .Reference_currentAmount__2-WAe .Reference_value__1myau {
    font-weight: bold;
}


@media only screen and (max-width: 767px) {
    .Reference_reference__3FvJn {
        padding-left: 10px;
        padding-right: 10px;
    }
    .Reference_reference__3FvJn .Reference_motivation__1KqIA h3 {
        display: none;
    }
    .Reference_referenceNewReference__3jFKk {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .Reference_reference__3FvJn {
        display: grid;
        grid-template-columns: 408px 1fr;
        grid-gap: 20px;
        gap: 20px;
    }
    .Reference_reference__3FvJn .Reference_motivation__1KqIA {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(/static/media/ico_fee_gold_145.c797ec1f.svg);
    }
    .Reference_reference__3FvJn .Reference_layout__14xWF {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
    .Reference_referenceNewReference__3jFKk {
        margin-top: 20px;
        margin-bottom: 50px;
    }
}
.Service_service__1scfa .Service_activityIndicatorContainer__2_BQg {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(240,240,240,1);
}
.Service_service__1scfa > .Service_activityIndicatorContainer__2_BQg {
    height: 80vh;
}
.Service_service__1scfa button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.Service_service__1scfa .Service_messageBox__15TUi p {
    color: red;
}
.Service_service__1scfa .Service_messageBox__15TUi.Service_negative__1s2a- p {
    color: red;
}
.Service_service__1scfa .Service_messageBox__15TUi.Service_pozitive__bzgRS p {
    color: green;
}
.Service_service__1scfa .Service_messageBox__15TUi button {
    background-color: lightgray;

}
.Service_service__1scfa .Service_messageBox__15TUi button:hover {
    background-color: red;
}

.Service_serviceNewComplaint__1vji6 {
    position: relative;
    top: 0;
    left: 0;
}
.Service_serviceNewComplaint__1vji6 button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.Service_serviceNewComplaint__1vji6 button:hover {
    background-color: red;
}
.Service_serviceNewComplaint__1vji6 .Service_serviceForm__2zI97 {
    position: relative;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 400px;
}
.Service_serviceNewComplaint__1vji6 .Service_serviceForm__2zI97 form {
    display: contents;
}
.Service_serviceNewComplaint__1vji6 .Service_serviceForm__2zI97 form label {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.Service_serviceNewComplaint__1vji6 .Service_serviceForm__2zI97 textarea {
    outline: none;
    -webkit-appearance: none;
            appearance: none;
    padding: 3px;
    border-radius: 3px;
    border: solid 1px #cccccc;
    font-size: 12pt;
    line-height: 1.7em;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 3px;
}
.Service_serviceNewComplaint__1vji6 .Service_serviceForm__2zI97 textarea:valid {
    padding-right: 27px;
    background-image: url(/static/media/valid.af2f90f8.svg);
    background-position: right 3px center;
    background-repeat: no-repeat;
}
.Service_serviceNewComplaint__1vji6 .Service_activityIndicatorContainer__2_BQg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,1);
}
.Service_serviceNewComplaint__1vji6 .Service_cancel__2Gzcp {
    background-color: lightgray;
}
.Service_serviceNewComplaint__1vji6 .Service_message__4riLY {
    margin-top: 40px;
}
.Service_serviceNewComplaint__1vji6 .Service_message__4riLY textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    min-height: 200px;
    font-size: 11pt;
    font-family: inherit;
}
.Service_serviceNewComplaint__1vji6 .Service_message__4riLY .Service_control__XX32b {
    margin-top: 20px;
}

@media only screen and (max-width: 767px) {
    .Service_service__1scfa {
        padding-left: 10px;
        padding-right: 10px;
    }
    .Service_service__1scfa .Service_motivation__KNvCr h3 {
        display: none;
    }
    .Service_serviceNewComplaint__1vji6 {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li {
        padding: 10px;
        box-sizing: border-box;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        border-radius: 5px;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li.Service_current__3io5J {
        border-color: #0b6fc7;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li .Service_severity__1Y0cy {
        display: block;
        line-height: 40px;
        font-size: 14pt;
        text-transform: capitalize;
        background-repeat: no-repeat;
        background-position: center left;
        background-size: 36px auto;
        padding-left: 40px;
        margin-bottom: 5px;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li.Service_severityService__2CymH .Service_severity__1Y0cy {
        background-image: url(/static/media/servis_1.a5590dcf.svg);
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li.Service_severityDefect__3uMHv .Service_severity__1Y0cy {
        background-image: url(/static/media/servis_2.da7af5f2.svg);
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li.Service_severityEmergency__Ts-26 .Service_severity__1Y0cy {
        background-image: url(/static/media/servis_3.42bd9b68.svg);
    }
}

@media only screen and (min-width: 768px) {
    .Service_service__1scfa {
        display: grid;
        grid-template-columns: 408px 1fr;
        grid-gap: 20px;
        gap: 20px;
    }
    .Service_service__1scfa .Service_motivation__KNvCr {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(/static/media/ico_servis_135.ad8905e4.svg);
    }
    .Service_service__1scfa .Service_layout__3hv6B {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
    .Service_serviceNewComplaint__1vji6 {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        gap: 20px;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li {
        border: solid 4px #f8f8f8;
        border-radius: 10px;
        padding: 120px 20px 20px 20px;
        background-position: center 20px;
        background-repeat: no-repeat;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li span {
        color: #000;
        color: initial;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li .Service_severity__1Y0cy {
        display: block;
        line-height: 2.5em;
        font-size: 14pt;
        text-transform: capitalize;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li.Service_severityService__2CymH {
        background-image: url(/static/media/servis_1.a5590dcf.svg);
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li.Service_severityDefect__3uMHv {
        background-image: url(/static/media/servis_2.da7af5f2.svg);
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li.Service_severityEmergency__Ts-26 {
        background-image: url(/static/media/servis_3.42bd9b68.svg);
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li.Service_current__3io5J {
        border-color: #0b6fc7;
    }
    .Service_serviceNewComplaint__1vji6 .Service_severity__1Y0cy ul li:hover {
        background-color: #eeeeee;
    }
}
.Communication_communication__38sHk .Communication_activityIndicatorContainer__3dW3W {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(240,240,240,1);
}

.Communication_communication__38sHk button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.Communication_communication__38sHk button:hover {
    background-color: red;
}

.Communication_communication__38sHk .Communication_messageBox__1GVax p {
    color: red;
}
.Communication_communication__38sHk .Communication_messageBox__1GVax.Communication_negative__G6_Kg p {
    color: red;
}
.Communication_communication__38sHk .Communication_messageBox__1GVax.Communication_pozitive__1HTje p {
    color: green;
}
.Communication_communication__38sHk .Communication_messageBox__1GVax button {
    background-color: lightgray;
}
.Communication_communication__38sHk .Communication_messageBox__1GVax button:hover {
    background-color: red;
}

.Communication_communication__38sHk .Communication_layout__AFv9B .Communication_activityIndicatorContainer__3dW3W {
    height: 80vh;
}


.Communication_communicationMessageList__3_w9I {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    /* height: 200px; */
    overflow: auto;
    margin-top: 40px;
}
.Communication_communicationMessageList__3_w9I li {
    margin-bottom: 30px;
}
.Communication_communicationMessageList__3_w9I li p {
    white-space: pre-line;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}
.Communication_communicationMessageList__3_w9I li.Communication_client__K-QMb {
    width: 80%;
    align-self: end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.Communication_communicationMessageList__3_w9I li.Communication_client__K-QMb p {
    background-color: #d1f1dc;
    border-radius: 10px;
    display: block;
    padding:  20px;
    margin: 0;
}
.Communication_communicationMessageList__3_w9I li.Communication_company__30Dj5 {
    width: 80%;
    align-self: start;
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 10px;
    row-gap: 10px;
}
.Communication_communicationMessageList__3_w9I li.Communication_company__30Dj5 img {
    grid-column: 1;
    grid-row: 1;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: solid 1px #cccccc;
}
.Communication_communicationMessageList__3_w9I li.Communication_company__30Dj5 p {
    grid-column: 2;
    grid-row: 1;
}
.Communication_communicationMessageList__3_w9I li .Communication_date__2RxiB {
    color: #cccccc;
}
.Communication_communicationMessageList__3_w9I li.Communication_company__30Dj5 .Communication_date__2RxiB {
    grid-row: 2;
    grid-column: 2;
}
.Communication_communicationMessageList__3_w9I li.Communication_company__30Dj5 p {
    background-color: #d1e5f6;
    border-radius: 10px;
    display: block;
    padding:  20px;
    margin: 0;
}


.Communication_communicationNewMessage__DVAMH {
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM .Communication_recipient__16jS5 {
    margin-bottom: 10px;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM .Communication_recipient__16jS5 .Communication_title__36F-z {
    font-weight: bold;
    margin-right: 10px;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM .Communication_recipient__16jS5 .Communication_title__36F-z::after {
    content: ":";
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM .Communication_messageForm__WDlfF {
    position:relative;
    top: 0;
    left: 0;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM .Communication_messageForm__WDlfF .Communication_activityIndicatorContainer__3dW3W {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM .Communication_messageForm__WDlfF .Communication_control__2xR93 {
    margin-top: 10px;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM .Communication_messageForm__WDlfF .Communication_control__2xR93 .Communication_cancel__1vxjv {
    background-color: lightgray;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM .Communication_messageForm__WDlfF .Communication_control__2xR93 .Communication_cancel__1vxjv:hover {
    background-color: red;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    min-height: 200px;
    font-size: 11pt;
    font-family: inherit;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM button {
    margin-right: 10px;
}
.Communication_communicationNewMessage__DVAMH > .Communication_message__1AiqM button:hover {
    background-color: red;
}

.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 .Communication_messageBox__1GVax button.Communication_reload__2i3sP {
    background-color: #0b6fc7;
    margin-right: 10px;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 .Communication_messageBox__1GVax button.Communication_reload__2i3sP:hover {
    background-color: red;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 .Communication_recipientList__9HL4e {
    min-height: 240px;
    position: relative;
    top: 0;
    left: 0;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 .Communication_recipientList__9HL4e .Communication_activityIndicatorContainer__3dW3W {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    grid-gap: 20px;
    gap: 20px;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 li {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    border: solid 1px #cbcbcb;
    padding: 10px 20px;
    border-radius: 5px;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 li:hover {
    background-color: #f5f5f5;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 span.Communication_position__3-R5J {
    font-size: 10pt;
    color: #505050;
    white-space: nowrap;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 span.Communication_name__2RdZl {
    margin-top: 5px;
    white-space: nowrap;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 button.Communication_cancel__1vxjv {
    background-color: lightgray;
    margin-top: 15px;
}
.Communication_communicationNewMessage__DVAMH > .Communication_recipient__16jS5 button.Communication_cancel__1vxjv:hover {
    background-color: red;
}


@media only screen and (max-width: 767px) {
    .Communication_communication__38sHk {
        padding-left: 10px;
        padding-right: 10px;
    }
    .Communication_communication__38sHk .Communication_motivation__hoBjc h3 {
        display: none;
    }
    .Communication_communication__38sHk .Communication_layout__AFv9B {
        margin-top: 40px;
    }
    .Communication_communicationMessageList__3_w9I li.Communication_company__30Dj5 {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .Communication_communication__38sHk {
        display: grid;
        grid-template-columns: 408px 1fr;
        grid-gap: 20px;
        gap: 20px;
    }
    .Communication_communication__38sHk .Communication_motivation__hoBjc {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(/static/media/ico_message_135.aeb42711.svg);
    }
    .Communication_communication__38sHk .Communication_layout__AFv9B {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    }
}
.Index_index__8YOrN ul li.Index_harmonogram__NoiIQ a {
    background-image: url(/static/media/ico_schedule_135.80665bc2.svg);
}
.Index_index__8YOrN ul li.Index_message__1Fn0U a {
    background-image: url(/static/media/ico_message_135.aeb42711.svg);
}
.Index_index__8YOrN ul li.Index_helpline__3lUs2 a {
    background-image: url(/static/media/ico_director_135.933b40e1.svg);
}
.Index_index__8YOrN ul li.Index_file__1fj7Z a {
    background-image: url(/static/media/ico_download_135.72725852.svg);
}
.Index_index__8YOrN ul li.Index_loan__HSk3v a {
    background-image: url(/static/media/ico_loans_135.a66b2db7.svg);
}
.Index_index__8YOrN ul li.Index_profile__1ypPV a {
    background-image: url(/static/media/ico_profile_135.537452c9.svg);
}
.Index_index__8YOrN ul li.Index_reference__3DWyP a {
    background-image: url(/static/media/ico_fee_gold_145.c797ec1f.svg);
}
.Index_index__8YOrN ul li.Index_service__3ovE4 a {
    background-image: url(/static/media/ico_servis_135.ad8905e4.svg);
}

@media only screen and (min-width: 768px) {
    .Index_index__8YOrN {
        margin-top: 40px;
    }
    .Index_index__8YOrN h2 {
        display: none;
    }
    .Index_index__8YOrN ul {
        margin-top: 80px;
        margin-bottom: 100px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 100px 0;
        gap: 100px 0;
    }
    .Index_index__8YOrN ul li {
        display: contents;
    }
    .Index_index__8YOrN ul li a {
        padding-top: 150px;
        background-size: 150px 150px;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .Index_index__8YOrN ul li.Index_reference__3DWyP {
        display: none;
    }
    .Index_index__8YOrN .Index_reference__3DWyP a {
        padding-left: 380px;
        background-size: 350px 250px;
        background-position: 0px top;
        background-repeat: no-repeat;
        background-image: url(/static/media/ico_fee_gold.0aa0e818.svg);
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        width: 300px;
        margin: 0 auto;
        height: 250px;
        margin-top: 100px;
    }
    .Index_index__8YOrN .Index_reference__3DWyP a h3::first-letter {
        text-transform: uppercase;
    }
    .Index_index__8YOrN .Index_reference__3DWyP a .Index_description__I8AbB {
        display: block;
        color: #000;
        color: initial;
    }
}
@media only screen and (max-width: 767px) {
    .Index_index__8YOrN {
        margin-top: 40px;
    }
    .Index_index__8YOrN h2 {
        /* display: none; */
        display: block;
        padding: 0 20px;
    }
    .Index_index__8YOrN ul {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        grid-gap: 30px 0;
        gap: 30px 0;
    }
    .Index_index__8YOrN ul li {
        display: contents;
    }
    .Index_index__8YOrN ul li a {
        padding-top: 120px;
        background-size: 120px 120px;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .Index_index__8YOrN > .Index_reference__3DWyP {
        display: none;
    }
}

